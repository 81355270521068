import instance from '../axios';
import {
  GET_QUESTIONS,
  SET_ERRORS,
  GET_AGENTS,
  GET_LANQUAGES,
  GET_SPECIALIZATIONS,
  GET_AREAS,
  GET_ADMIN_AGENTS,
  GET_AGENT,
  GET_AGENT_CONTACTS,
  GET_AGENT_PERSON_INFO,
  GET_AGENT_LANGUAGES,
  GET_AGENT_SPECIALITY,
  GET_AGENT_AREAS,
  GET_AGENT_QUALIFICATION,
  PUT_AGENT_PERSON_INFO,
  GET_AGENT_VETTING,
  GET_ANALYTICS_LEADTRENDS,
  GET_ANALYTICS_PROFILEVIEWS,
  GET_ANALYTICS_SEARCHRESULTS,
  GET_AGENT_SUBSCRIPTION_DATA,
  GET_SPECIALTY_QUESTIONS,
  GET_EMAIL_REGISTRATION_STATUS,
} from './action-variable';
import { toastr } from 'react-redux-toastr';
import { errors } from '../constants/errors';
import jwt_decode from 'jwt-decode';
import { storage } from '../shared/utils/storage';

const handleError = (error) => {
  console.error({ error });
  const errorMessage = errors?.find(
    (errorCode) => error?.data?.status === errorCode?.statusCode
  );
  toastr.error(
    errorMessage?.comment || 'Something went wrong! Please try again'
  );
};

export const checkForExpiredToken = () => () => {
  const token = localStorage.getItem('token');
  if (token) {
    const currentTimeInSeconds = Date.now() / 1000;
    const user = jwt_decode(token);
    if (user.exp >= currentTimeInSeconds) {
      setAuthToken(token);
    }
    return;
  }

  setAuthToken(token);
};

export const login =
  ({ email, password, proceed = (f) => f, reject = (f) => f }) =>
  async (dispatch) => {
    try {
      const res = await instance.post(`/AuthAgents/Login`, {
        email,
        password,
      });
      proceed(res?.data?.data?.token);
    } catch (error) {
      handleError(error);
      reject();
    }
  };

export const register = (userData, setStep, step) => async (dispatch) => {
  try {
    const res = await instance.post(`/AuthAgents/Register`, userData);
    setStep(step + 1);
    localStorage.setItem('currentStep', step + 1);
    setAuthRegisterToken(res?.data?.data?.token);
  } catch (error) {
    handleError(error);
  }
};

export const registerWithAllData =
  ({ formData, setToken, proceed = (f) => f }) =>
  async (dispatch) => {
    try {
      const res = await instance.post(
        `/AuthAgents/RegisterAgentWithAllData`,
        formData
      );
      setToken(res?.data?.data?.token);
      proceed();
    } catch (error) {
      handleError(error);
    }
  };

export const updateAgentWithAllData =
  ({ formData, proceed = (f) => f }) =>
  async (dispatch) => {
    try {
      const res = await instance.put(
        `/AuthAgents/UpdateAgentWithAllData`,
        formData
      );
      setAuthRegisterToken(res?.data?.data?.token);
      proceed();
    } catch (error) {
      handleError(error);
    }
  };

export const adminLogin =
  ({ email, password, proceed = (f) => f, reject = (f) => f }) =>
  async (dispatch) => {
    try {
      const res = await instance.post(`/AuthAgents/Login/Admin`, {
        email,
        password,
      });
      proceed(res?.data?.data?.token);
    } catch (error) {
      handleError(error);
      reject();
    }
  };

export const adminRegister =
  ({ payload, proceed = (f) => f, reject = (f) => f }) =>
  async (dispatch) => {
    try {
      const res = await instance.post(`/AuthAgents/Register/Admin`, payload);
      proceed(res?.data?.data?.token);
    } catch (error) {
      handleError(error);
      reject();
    }
  };

export const checkEmail = (emailValue, handleNext) => async (dispatch) => {
  try {
    const res = await instance.get(
      `/AuthAgents/CheckEmail?email=${emailValue}`
    );
    const status = res.data.status;
    dispatch({
      type: GET_EMAIL_REGISTRATION_STATUS,
      payload: status === 0 ? true : false,
    });
    if (status === 0) handleNext();
  } catch (error) {
    handleError(error);
  }
};

export const setAuthToken = (token, initState, navigateTo) => {
  if (token) {
    const decoded = jwt_decode(token);
    initState(token);
    if (decoded.iss === 'admin') {
      return navigateTo('/admin-dashboard');
    }
    return navigateTo('/dashboard');
  }
  localStorage.removeItem('token');
  localStorage.clear();
  return navigateTo('/login');
};

export const setAuthRegisterToken = (token) => {
  if (token) {
    storage.setItem('token', token);
    return;
  }
  storage.removeItem('token');
  storage.clear();
};

export const getQuestions = () => async (dispatch) => {
  try {
    const res = await instance.get('/Questions/GetAll', {});
    const questions = res?.data?.data?.sort(function (a, b) {
      return a.typeId - b.typeId;
    });
    dispatch({
      type: GET_QUESTIONS,
      payload: questions,
    });
  } catch (error) {
    handleError(error);
  }
};

export const postPersonalInfo = (data, setStep, step) => async (dispatch) => {
  try {
    if (localStorage.getItem('token')) {
      await instance.put('/AuthAgents/PersonalInfo', data);
      setStep(step + 1);
      localStorage.setItem('currentStep', step + 1);
    }
  } catch (error) {
    handleError(error);
  }
};

export const postPhoneNumber = (data, setStep, step) => async (dispatch) => {
  try {
    if (localStorage.getItem('token')) {
      await instance.put('/AuthAgents/PhoneNumber', data);
      setStep(step + 1);
      localStorage.setItem('currentStep', step + 1);
    }
  } catch (error) {
    handleError(error);
  }
};

export const postAddress = (data, setStep, step) => async (dispatch) => {
  try {
    if (localStorage.getItem('token')) {
      await instance.put('/AuthAgents/Areas', data);
      setStep(step + 1);
    }
  } catch (error) {
    handleError(error);
  }
};

export const postQuestions = async (data, setStep, step, setNotQualified) => {
  return instance.post('/AuthAgents/IsQualify', data).then(
    (res) => {
      const unqualifiedAgent = res?.data?.data?.length;
      if (unqualifiedAgent) {
        setNotQualified(res?.data?.data);
        localStorage.setItem('currentStep', 1);
        localStorage.removeItem('token');
        return { unqualifiedAgent };
      }
      setStep(step + 1);
      localStorage.setItem('currentStep', step + 1);
      return { unqualifiedAgent };
    },
    (error) => {
      handleError(error);
    }
  );
};

export const postLicenceAndBrokage =
  (data, setStep, step) => async (dispatch) => {
    try {
      if (localStorage.getItem('token')) {
        await instance.put('/AuthAgents/LicenseAndBrokerage', data);
        setStep(step + 1);
        localStorage.setItem('currentStep', step + 1);
      }
    } catch (error) {
      handleError(error);
    }
  };

export const pay = (data) => async (dispatch) => {
  try {
    if (localStorage.getItem('token')) {
      const res = await instance.post('/Payments/session', data);
      window.location.replace(res?.data?.data?.url);
    }
  } catch (error) {
    handleError(error);
  }
};

export const manageSubscriptionUrl = (returnUrl) => async (dispatch) => {
  try {
    if (localStorage.getItem('token')) {
      const res = await instance.get(
        `/Payments/ManageSubscriptionUrl?returnUrl=${returnUrl}`
      );
      window.location.replace(res?.data?.data?.url);
    }
  } catch (error) {
    handleError(error);
  }
};

const transactionPayload = (index, data) => {
  let transactionId = localStorage.getItem(`transactionId${index}`);
  let vettingId = localStorage.getItem(`vettingId${index}`);
  if (transactionId && vettingId) {
    const payload = { ...data };
    transactionId = Number(transactionId);
    vettingId = Number(vettingId);
    payload.id = transactionId;
    payload.vettingId = vettingId;
    payload.lenderRequestDto = {
      ...payload.lenderRequestDto,
      transactionId,
      vettingId,
    };
    return payload;
  }

  return data;
};

export const postTransaction1 =
  (data, setStepFunc, proceed = (f) => f) =>
  async (dispatch) => {
    try {
      if (localStorage.getItem('token')) {
        const res = await instance.post(
          '/Vettings/create/transaction?step=1',
          transactionPayload(1, data)
        );
        setTransactionData(res?.data?.data);
        dispatch({
          type: GET_AGENT_VETTING,
          payload: res?.data?.data || null,
        });
        proceed();
        setStepFunc();
      }
    } catch (error) {
      proceed();
      handleError(error);
    }
  };

export const postLenderStep =
  (data, setStepFunc, currentStep, proceed = (f) => f) =>
  async (dispatch) => {
    try {
      if (localStorage.getItem('token')) {
        const res = await instance.post(
          `/Vettings/create/lender?step=${currentStep}`,
          data
        );
        dispatch({
          type: GET_AGENT_VETTING,
          payload: res?.data?.data ?? null,
        });
        proceed();
        setStepFunc();
      }
    } catch (error) {
      proceed();
      handleError(error);
    }
  };

export const postTransaction2 =
  (data, setStepFunc, proceed = (f) => f) =>
  async (dispatch) => {
    try {
      if (localStorage.getItem('token')) {
        const res = await instance.post(
          '/Vettings/create/transaction?step=3',
          transactionPayload(2, data)
        );
        setTransactionData(data);
        dispatch({
          type: GET_AGENT_VETTING,
          payload: res?.data?.data ?? null,
        });
        proceed();
        setStepFunc();
      }
    } catch (error) {
      proceed();
      handleError(error);
    }
  };

export const postCompanyStep =
  (data, setStepFunc, proceed = (f) => f) =>
  async (dispatch) => {
    try {
      if (localStorage.getItem('token')) {
        const res = await instance.post(
          '/Vettings/create/company?step=5',
          data
        );
        dispatch({
          type: GET_AGENT_VETTING,
          payload: res?.data?.data ?? null,
        });
        proceed();
        setStepFunc();
      }
    } catch (error) {
      proceed();
      handleError(error);
    }
  };

export const postClientsStep =
  (data, setStepFunc, proceed = (f) => f) =>
  async (dispatch) => {
    try {
      if (localStorage.getItem('token')) {
        const res = await instance.post('/Vettings/create/client?step=6', data);
        dispatch({
          type: GET_AGENT_VETTING,
          payload: res?.data?.data ?? null,
        });
        proceed();
        setStepFunc();
      }
    } catch (error) {
      proceed();
      handleError(error);
    }
  };

export const getCurrentUser = () => async (dispatch) => {
  try {
    const res = await instance.get('/Vettings/CurrentUser');
    setTransactionData(res?.data?.data);
    dispatch({
      type: GET_AGENT_VETTING,
      payload: res?.data?.data ?? null,
    });
  } catch (error) {
    handleError(error);
  }
};

export const setTransactionData = (data) => {
  if (data) {
    localStorage.setItem('data', data);
    localStorage.setItem('agentId', data.agentId);
    data?.transactions?.forEach((transaction, i) => {
      localStorage.setItem(`vettingId${i + 1}`, transaction.vettingId);
      localStorage.setItem(`transactionId${i + 1}`, transaction.id);
    });
    return;
  }
  localStorage.clear();
};

export const recoverAgentPassword =
  ({ userData }) =>
  async (dispatch) => {
    try {
      await instance.get(
        `/AuthAgents/RecoverAgentPasswordRequest?email=${userData.email}`
      );
    } catch (error) {
      handleError(error);
    }
  };

export const newPassword =
  ({ userData }) =>
  async (dispatch) => {
    try {
      await instance.get(
        `AuthAgents/SetNewAgentPassword?token=${userData.token}&newPassword=${userData.newPassword}`
      );
    } catch (error) {
      handleError(error);
    }
  };

export const getErrors = () => async (dispatch) => {
  try {
    const res = await instance.get('/ApiResponses/GetAll', {});
    dispatch({
      type: SET_ERRORS,
      payload: res?.data?.data,
    });
  } catch (error) {
    handleError(error);
  }
};

export const getAdminAgents =
  ({ page = 1, search = '', status = 'all' }) =>
  async (dispatch) => {
    try {
      const params = { search };

      if (status !== 'all') {
        params.status = status;
      }

      const res = await instance.post(
        '/Agents/Admin/Query',
        {
          pagedParams: {
            pageNumber: page,
            pageSize: 10,
          },
          sortedParams: {
            columns: {
              additionalProp1: '',
              additionalProp2: '',
              additionalProp3: '',
            },
          },
        },
        {
          params,
        }
      );
      dispatch({
        type: GET_ADMIN_AGENTS,
        payload: res?.data?.data,
      });
    } catch (error) {
      handleError(error);
    }
  };

export const getAgents = (data) => async (dispatch) => {
  try {
    const res = await instance.get('/Agents/Find', {
      params: data,
    });
    const agents = res?.data?.data;
    dispatch({
      type: GET_AGENTS,
      payload: agents,
    });
  } catch (error) {
    handleError(error);
  }
};

export const getLanguages = () => async (dispatch) => {
  try {
    const res = await instance.get('/Agents/Languages', {});
    dispatch({
      type: GET_LANQUAGES,

      payload: res?.data?.data,
    });
  } catch (error) {
    handleError(error);
  }
};

export const getSpecializations = () => async (dispatch) => {
  try {
    const res = await instance.get('/Agents/Specializations', {});
    dispatch({
      type: GET_SPECIALIZATIONS,
      payload: res?.data?.data,
    });
  } catch (error) {
    handleError(error);
  }
};

export const getAreas = () => async (dispatch) => {
  try {
    const res = await instance.get('/Agents/AllAreas', {});
    dispatch({
      type: GET_AREAS,
      payload: res?.data?.data,
    });
  } catch (error) {
    handleError(error);
  }
};

export const getAgent = (id) => async (dispatch) => {
  try {
    const res = await instance.get(`/Agents/${id}`);
    dispatch({
      type: GET_AGENT,
      payload: res?.data?.data,
    });
  } catch (error) {
    handleError(error);
  }
};

export const postContactRequest =
  (data, setContactSentModel) => async (dispatch) => {
    try {
      await instance.post('/Agents/ContactRequest', data);
      setContactSentModel((prev) => !prev);
    } catch (error) {
      handleError(error);
    }
  };

export const getAgentContacts = () => async (dispatch) => {
  try {
    const res = await instance.get('/Agents/Contacts');
    dispatch({
      type: GET_AGENT_CONTACTS,
      payload: res?.data?.data || [],
    });
  } catch (error) {
    handleError(error);
  }
};

export const getAgentPersonInfo = () => async (dispatch) => {
  try {
    const res = await instance.get('/Agents/PersonalInfo');
    dispatch({
      type: GET_AGENT_PERSON_INFO,
      payload: res?.data?.data || {},
    });
  } catch (error) {
    handleError(error);
  }
};

export const getAgentLanguages = () => async (dispatch) => {
  try {
    const res = await instance.get('/Agents/Languages');
    dispatch({
      type: GET_AGENT_LANGUAGES,
      payload: res?.data?.data || [],
    });
  } catch (error) {
    handleError(error);
  }
};

export const getAgentSpecialty = () => async (dispatch) => {
  try {
    const res = await instance.get('/Agents/SpecialtyLanguage');

    dispatch({
      type: GET_AGENT_SPECIALITY,
      payload: res?.data?.data || [],
    });
  } catch (error) {
    handleError(error);
  }
};

export const getAgentSpecialtyQuestions = () => async (dispatch) => {
  try {
    const res = await instance.get('/SpecialtyLanguage');

    dispatch({
      type: GET_SPECIALTY_QUESTIONS,
      payload: res?.data?.data || [],
    });
  } catch (error) {
    handleError(error);
  }
};

export const getAgentAreas = () => async (dispatch) => {
  try {
    const res = await instance.get('/Agents/Areas');

    dispatch({
      type: GET_AGENT_AREAS,
      payload: res?.data?.data || [],
    });
  } catch (error) {
    handleError(error);
  }
};

export const getAgentQualification = () => async (dispatch) => {
  try {
    const res = await instance.get('/Agents/Qualification');

    dispatch({
      type: GET_AGENT_QUALIFICATION,
      payload: res?.data?.data || [],
    });
  } catch (error) {
    handleError(error);
  }
};

// update functions
export const putAgentPersonInfo = (payload, callback) => async (dispatch) => {
  try {
    const res = await instance.put('/AuthAgents/PersonalInfo', payload);
    toastr.success('Your personal information was updated successfully!');
    dispatch({
      type: PUT_AGENT_PERSON_INFO,
      payload: res?.data?.data || [],
    });
    callback();
  } catch (error) {
    handleError(error);
  }
};

export const putAgentPhoneNumber = (payload, callback) => async (dispatch) => {
  try {
    const res = await instance.put('/AuthAgents/PhoneNumber', payload);
    toastr.success('Your Phone Number was updated successfully!');
    dispatch({
      type: PUT_AGENT_PERSON_INFO,
      payload: res?.data?.data || [],
    });
    callback();
  } catch (error) {
    handleError(error);
    callback();
  }
};

export const putAgentLicenseAndBrokerage =
  (payload, callback) => async (dispatch) => {
    try {
      const res = await instance.put(
        '/AuthAgents/LicenseAndBrokerage',
        payload
      );
      toastr.success(
        'Your License and Brokerage information was updated successfully!'
      );
      dispatch({
        type: PUT_AGENT_PERSON_INFO,
        payload: res?.data?.data || [],
      });
      callback();
    } catch (error) {
      handleError(error);
    }
  };

export const putAgentImage =
  (payload, showMessage = true) =>
  async (dispatch) => {
    try {
      const res = await instance.put('/AuthAgents/upload/photo', payload);
      showMessage && toastr.success('Your profile image updated!');
      dispatch({
        type: PUT_AGENT_PERSON_INFO,
        payload: res?.data?.data || [],
      });
    } catch (error) {
      handleError(error);
    }
  };

export const putAgentQualification =
  (payload, callback) => async (dispatch) => {
    try {
      const res = await instance.put('/AuthAgents/Questions', payload);
      toastr.success('Your Qualifications updated!');
      dispatch({
        type: PUT_AGENT_PERSON_INFO,
        payload: res?.data?.data || [],
      });
      callback();
    } catch (error) {
      handleError(error);
      callback();
    }
  };

export const putAgentSpecialtyLanguage =
  (payload, callback) => async (dispatch) => {
    try {
      await instance.put('/AuthAgents/SpecLangQuestions', payload);
      toastr.success('Your Specialty and Languages updated!');
      callback();
    } catch (error) {
      handleError(error);
    }
  };

export const putAgentAreas = (payload, callback) => async (dispatch) => {
  try {
    const res = await instance.put('/AuthAgents/Areas', payload);
    toastr.success('Your areas updated!');
    callback();
    dispatch({
      type: GET_AGENT_AREAS,
      payload: res?.data?.data || [],
    });
  } catch (error) {
    handleError(error);
    callback();
  }
};

// vetting process

export const getAgentVetting = () => async (dispatch) => {
  try {
    const res = await instance.get('/Vettings/CurrentUser');
    dispatch({
      type: GET_AGENT_VETTING,
      payload: res?.data?.data || null,
    });
  } catch (error) {
    handleError(error);
  }
};

// analyses data

export const getLeadTrends =
  (days = 30) =>
  async (dispatch) => {
    try {
      const res = await instance.get(`/Analytics/LeadTrends/${days}`);
      dispatch({
        type: GET_ANALYTICS_LEADTRENDS,
        payload: res?.data?.data || null,
      });
    } catch (error) {
      handleError(error);
    }
  };

export const getProfileViews =
  (days = 30) =>
  async (dispatch) => {
    try {
      const res = await instance.get(`/Analytics/ProfileViews/${days}`);
      dispatch({
        type: GET_ANALYTICS_PROFILEVIEWS,
        payload: res?.data?.data || null,
      });
    } catch (error) {
      handleError(error);
    }
  };

export const getSearchResults =
  (days = 30) =>
  async (dispatch) => {
    try {
      const res = await instance.get(`/Analytics/SearchResults/${days}`);
      dispatch({
        type: GET_ANALYTICS_SEARCHRESULTS,
        payload: res?.data?.data || null,
      });
    } catch (error) {
      handleError(error);
    }
  };

export const postPreRegisterAgent = (data, callback) => async (dispatch) => {
  try {
    await instance.post(`/Agents/PreRegisterAgent`, data);
    callback();
  } catch (error) {
    handleError(error);
  }
};

export const getSubscriptionData = () => async (dispatch) => {
  try {
    const res = await instance.get(`/Payments/GetSubscriptionData`);
    const subscriptionData = res?.data?.data;
    dispatch({
      type: GET_AGENT_SUBSCRIPTION_DATA,
      payload: subscriptionData,
    });
  } catch (error) {
    handleError(error);
  }
};
