import { useContext } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Box from '@mui/material/Box';
import Logo from '../../assets/images/Marketpros.svg';
import UserIcon from '../../assets/images/User.svg';
import ButtonGold from '../../shared/components/ButtonGold';
import { useNavigate } from 'react-router-dom';
import Logout from '../Dashboard/components/logout';
import { AuthContext } from '../../shared/context/auth.context';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: '100%',
      marginBottom: '50px',
      boxShadow: 'None',
      fontFamily: theme.Typography.fontFamily,
    },

    menuButton: {
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(10),
      marginLeft: theme.spacing(1),
    },
    menuWrapper: {
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto',
      gap: '2rem',
      marginTop: theme.spacing(5),
    },
    title: {
      textAlign: 'center',
      fontSize: '15px',
      whiteSpace: 'nowrap',
      fontFamily: 'Inter',
      fontWeight: '500',
      cursor: 'pointer',
    },
    logo: {
      maxWidth: 500,
      marginRight: '0.7rem',
      cursor: 'pointer',
    },
    registerButton: {
      margin: '0 3rem',
      marginRight: '4em',
    },
    loginAndRegister: {
      display: 'flex',
      flexDirection: 'row',
      overflow: 'hidden',
    },
    loginButton: {
      textAlign: 'right',
      marginLeft: 'auto',
      textTransform: 'none',
      marginRight: theme.spacing(1),
      fontFamily: 'Inter',
      fontWeight: '500',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
      },
      '&:active': {
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
      },
    },
  })
);

export default function ButtonAppBar({ withNavigation }) {
  const { isLoggedIn, isAdmin } = useContext(AuthContext);
  const classes = useStyles();
  const navigate = useNavigate();

  const handleLogin = () => {
    if (isLoggedIn) {
      if (isAdmin) {
        return navigate('/admin-dashboard');
      }
      return navigate('/dashboard');
    }
    return navigate('/register');
  };

  const goToFindMarketprosPage = () => navigate('/find-marketpros');

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => navigate('/')}
        ></IconButton>
        <img
          src={Logo}
          alt="Marketpros"
          className={classes.logo}
          onClick={() => navigate('/')}
        />
        {withNavigation && (
          <>
            <Box component="div" className={classes.menuWrapper}>
              <Typography
                className={classes.title}
                onClick={goToFindMarketprosPage}
              >
                Find Marketpros
              </Typography>
            </Box>
            <Box className={classes.loginAndRegister}>
              <ButtonGold
                className={classes.registerButton}
                onClick={handleLogin}
              >
                {!isLoggedIn ? 'Become Marketpros' : 'Dashboard'}
              </ButtonGold>
              {!isLoggedIn ? (
                <Button
                  color="inherit"
                  className={classes.loginButton}
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  <img src={UserIcon} alt="logo" className={classes.logo} />
                  Login
                </Button>
              ) : (
                <Logout />
              )}
            </Box>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}
