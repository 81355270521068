export const GET_QUESTIONS = 'GET_QUESTIONS';
export const SET_ERRORS = 'SET_ERRORS';
export const SET_LOADING = 'SET_LOADING';
export const SET_LOADING_SUCESS = 'SET_LOADING_SUCESS';
export const GET_AGENTS = 'GET_AGENTS';
export const GET_LANQUAGES = 'GET_LANQUAGES';
export const GET_SPECIALIZATIONS = 'GET_SPECIALIZATIONS';
export const GET_AREAS = 'GET_AREAS';
export const GET_ADMIN_AGENTS = 'GET_ADMIN_AGENTS';
export const GET_AGENT = 'GET_AGENT';
export const GET_ADMIN_PERSONAL_INFO = 'GET_ADMIN_PERSONAL_INFO';
export const GET_GENERAL_ADMIN_PERSONAL_INFO =
  'GET_GENERAL_ADMIN_PERSONAL_INFO';
export const GET_AGENT_APPLICATIONS = 'GET_AGENT_APPLICATIONS';
export const GET_AGENT_APPLICATION = 'GET_AGENT_APPLICATION';
export const GET_AGENT_TRANSACTIONS = 'GET_AGENT_TRANSACTIONS';
export const GET_AGENT_REFERENCES = 'GET_AGENT_REFERENCES';
export const GET_REFERENCE_PERSON = 'GET_REFERENCE_PERSON';

export const GET_QUESTIANAIRY = 'GET_QUESTIANAIRY';
export const GET_QUESTIANAIRY_WITH_DATA = 'GET_QUESTIANAIRY_WITH_DATA';
export const GET_AGENT_DATA_QUESTIANAIRY = 'GET_AGENT_DATA_QUESTIANAIRY';
export const GET_SUBSCRIPTION_DATA = 'GET_SUBSCRIPTION_DATA';

export const GET_AGENT_CONTACTS = 'GET_AGENT_CONTACTS';
export const GET_AGENT_PERSON_INFO = 'GET_AGENT_PERSON_INFO';
export const GET_AGENT_LANGUAGES = 'GET_AGENT_LANGUAGES';
export const GET_AGENT_SPECIALITY = 'GET_AGENT_SPECIALITY';
export const GET_AGENT_AREAS = 'GET_AGENT_AREAS';
export const GET_AGENT_QUALIFICATION = 'GET_AGENT_QUALIFICATION';

export const PUT_AGENT_PERSON_INFO = 'PUT_AGENT_PERSON_INFO';
export const GET_AGENT_VETTING = 'GET_AGENT_VETTING';

export const GET_ANALYTICS_LEADTRENDS = 'GET_ANALYTICS_LEADTRENDS';
export const GET_ANALYTICS_PROFILEVIEWS = 'GET_ANALYTICS_PROFILEVIEWS';
export const GET_ANALYTICS_SEARCHRESULTS = 'GET_ANALYTICS_SEARCHRESULTS';
export const GET_AGENT_SUBSCRIPTION_DATA = 'GET_AGENT_SUBSCRIPTION_DATA';

// Questions
export const GET_SPECIALTY_QUESTIONS = 'GET_SPECIALTY_QUESTIONS';

// Agent Registration Validation
export const GET_EMAIL_REGISTRATION_STATUS = 'GET_EMAIL_REGISTRATION_STATUS';
