import jwtDecode from 'jwt-decode';
import { useEffect, createContext, useState } from 'react';
import { storage } from '../utils/storage';

const AuthContext = createContext(undefined);

const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState(null);

  const logout = () => {
    storage.clear();
    setAuthState(null);
  };

  const initState = (token = storage.getItem('token')) => {
    if (token) {
      const decodedUser = jwtDecode(token);
      storage.setItem('token', token);

      setAuthState({
        ...decodedUser,
        token,
        isAdmin: decodedUser?.iss === 'admin',
        isAgent: decodedUser?.iss === 'agent',
      });
    }
    return null;
  };

  const isAdmin = () => {
    return !!authState?.isAdmin;
  };

  const isAgent = () => {
    return !!authState?.isAgent;
  };

  useEffect(() => {
    if (authState?.token !== storage.getItem('token')) {
      initState(storage.getItem('token'));
    }
  }, [authState]);

  return (
    <Provider
      value={{
        authState,
        isAdmin: isAdmin(),
        isAgent: isAgent(),
        logout,
        initState,
        isLoggedIn: !!authState,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthProvider, AuthContext };
