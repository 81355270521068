import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import ArrowUp from '../../assets/images/ShapeUp.svg';

import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingTop: '2rem',
      backgroundColor: theme.palette.primary.dark,
      display: 'flex',
      flexDirection: 'row',
      fontWeight: '400',
      fontSize: '18px',
      lineHeight: '28.8px',
      boxShadow: 'None',
      '& .MuiTypography-body': {
        fontFamily: 'Inter, sans-serif',
      },
      position: 'initial',
      clipPath: 'ellipse(80% 100% at 50% 100%)',
    },
    copyright: {
      textAlign: 'right',
      marginRight: 'auto',
      marginLeft: theme.spacing(5),
      marginTop: theme.spacing(15),
      marginBottom: theme.spacing(5),
    },
    menuWrapper: {
      display: 'none',
      flexDirection: 'row',
      gap: '5rem',
      marginBottom: theme.spacing(5),
      marginRight: theme.spacing(5),
    },
    sideMenuWrapper: {
      alignItems: 'center',
      textAlign: 'left',
    },
    item: {
      padding: '1rem',
    },
    image: {
      marginLeft: 'auto',
      marginRight: theme.spacing(3),
    },
  })
);
export default function Footer() {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.root}>
      <Typography className={classes.copyright}>
        ©2021 Marketpros, All rights reserved
      </Typography>
      <Box className={classes.menuWrapper}>
        <Box className={classes.sideMenuWrapper}>
          <Typography className={classes.item}>Home</Typography>
          <Typography className={classes.item}>Our Story</Typography>
          <Typography className={classes.item}>Our Process</Typography>
        </Box>
        <Box className={classes.sideMenuWrapper}>
          <Typography className={classes.item}>Education</Typography>
          <Typography className={classes.item}>Blog</Typography>
          <Typography className={classes.item}>Contact</Typography>
        </Box>
      </Box>
      <img alt="" src={ArrowUp} className={classes.image} width="75" />
    </AppBar>
  );
}
